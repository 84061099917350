
  import ScreenSize from '@/mixins/ScreenSize';
  import EventBus from '@/events/EventBus';
  import Events from '@/events/Events';
  import clearableTabIndex from '@/mixins/clearableTabIndex';
  import DialogsBaseSection from '@/components/sections/DialogsBaseSection.vue';
  import LoginOAuth from '@/mixins/LoginOAuth';

  export default {
    name: 'LoginForm',
    components: {
      DialogsBaseSection,
      RichText: () => import('@/components/elements/RichText'),
    },
    mixins: [clearableTabIndex, LoginOAuth, ScreenSize],
    props: {
      job: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {
        loginData: {email: '', password: ''},
        loginError: null,
        section: {},
        showPassword: false,
        buttonsMenu: false,
        disabled: false,
      };
    },
    async fetch() {
      this.section = await this.$cachedStrapi
        .find('login-dialog')
        .catch((e) => {
          if (e.statusCode !== 404) {
            throw e;
          }
        });
    },
    methods: {
      login() {
        this.disabled = true;
        this.$auth
          .loginWith('local', {data: this.loginData})
          .then(() => {
            EventBus.$emit('login');
            this.$emit('close-dialog');
            this.resetDialog();

            this.$notifications.showNotification({
              message: this.$t('action.user_login'),
              color: 'success',
              timeout: 5000,
            });

            this.$store.dispatch(
              'profileCompleteness/calculateProfileCompleteness'
            );
            this.$emitWithClientData(
              Events.RegistrationAndApplicationStepFinished,
              {
                step: 'Login',
                jobPostingId: this.job.id,
              }
            );
            this.$emitWithClientData(Events.LoggedIn, {
              type: 'RegistrationAndApplication',
              channel: 'default',
            });
          })
          .catch((e) => {
            if (e.response === undefined || e.response.status === undefined) {
              throw e;
            }
            this.loginError = e.response.status;
          });
        setTimeout(() => (this.disabled = false), 3000);
      },
      async sendResetPasswordRequest() {
        const emailValidation = this.$refs.emailValidation;
        const validator = await emailValidation.validate();
        if (validator.valid === true) {
          this.$store
            .dispatch('api/candidateAccount/sendEmailForForgotPassword', {
              email: this.loginData.email,
            })
            .then((response) => {
              if (response.status === 200) {
                this.$emit('close-dialog', 'Login');
                this.$emitWithClientData(
                  Events.RegistrationAndApplicationForgetPassword,
                  {
                    type: true,
                  }
                );
                this.$notifications.showNotification({
                  message: this.$t('forgot.password.send_email_success'),
                  color: 'success',
                  timeout: 5000,
                });
                this.$emit('close-dialog', 'Login');
              }
            })
            .catch((e) => {
              this.$emitWithClientData(
                Events.RegistrationAndApplicationForgetPassword,
                {type: false}
              );
              if (e.response.status !== 400) {
                this.$notifications.showNotification({
                  message: this.$t('forgot.password.send_email_success'),
                  color: 'success',
                  timeout: 5000,
                });
                this.$emit('close-dialog', 'Login');
                throw e;
              }
              this.$notifications.showNotification({
                message: this.$t('forgot.password.send_email_warning'),
                color: 'warning',
                timeout: 5000,
              });
            });
        } else {
          this.$notifications.showNotification({
            message: this.$t('forgot.password.missing_email_error'),
            color: 'error',
            timeout: 3000,
          });
        }
      },
      sendMatomoEvent(type) {
        if (type === 'facebook') {
          this.$emitWithClientData(
            Events.RegistrationAndApplicationSwitchToFacebook,
            {
              type: 'Login',
              jobPostingId: this.job.id,
            }
          );
        } else if (type === 'google') {
          this.$emitWithClientData(
            Events.RegistrationAndApplicationSwitchToGoogle,
            {
              type: 'Login',
              jobPostingId: this.job.id,
            }
          );
        } else if (type === 'apple') {
          this.$emitWithClientData(
            Events.RegistrationAndApplicationSwitchToApple,
            {
              type: 'Login',
              jobPostingId: this.job.id,
            }
          );
        } else {
          this.$emitWithClientData(
            Events.RegistrationAndApplicationSwitchToMicrosoft,
            {
              type: 'Login',
              jobPostingId: this.job.id,
            }
          );
        }
      },
      resetDialog() {
        this.loginData = {email: '', password: ''};
      },
    },
  };
